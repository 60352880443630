.metric-container {
  height: 100%;
  width: 100%;
  padding-bottom: 3px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.metric-title {
  /* padding: 0px; */
  /* background-color: #f5f5f5; */
  /* border-bottom: 1px solid #e0e0e0; */
  text-align: center;
  font-weight: bold !important;
  font-size: 18px !important;
}

.metric-tabs {
  /* background-color: #ffffff; */
}

.metric-tab-panel {
  height: calc(100% - 48px); /* Subtract the height of tabs */
  overflow: auto;
}

.metric-graph-container,
.metric-guidance-container,
.metric-actuals-container,
.metric-json-container {
  height: 100%;
  width: 100%;
  overflow: auto;
}
