.metric-json-container {
    position: relative;
    height: 100%;
    overflow: auto;
}

.metric-json-content {
    font-family: monospace;
    font-size: 0.7rem;
    white-space: pre-wrap;
    word-break: break-word;
    margin: 0;
    padding-top: 24px; 
}

.copy-button {
    position: absolute !important;
    top: 30px;
    right: 30px;
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.copy-button:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
}