.metric-guidance-boundary-box {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: 5px;
    overflow: auto;
}

.search-data-grid {
    border: none;
}