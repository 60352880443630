.nda-card {
  display: flex;
  flex-direction: column;
}

.nda-content {
  flex: 1 1 auto;
  overflow-y: auto;
}

.nda-actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  flex: 0 0 auto;
}

.nda-checkbox-label {
  margin-top: 8px;
  margin-bottom: 8px;
}
