.source-document-boundary-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.source-document-title {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
    margin: 0;
}

.source-document-tabs {
    /* background-color: #f5f5f5; */
    /* border-bottom: 1px solid #e0e0e0; */
}

.source-document-tab-panel {
    height: calc(100% - 40px); /* Subtract the height of the tabs */
    overflow: auto;
}

/* Styles for SourceDocumentTranscript and SourceDocumentPDF */
.source-document-tab-panel > div {
    height: 100%;
    overflow: auto;
}