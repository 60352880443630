.content-container {
  height: calc(100vh - 62px - 24px - 2px);
  overflow-y: auto; /* This will introduce scroll only if content inside is taller than the container */
}

.main-component-container {
  height: calc(100vh - 62px - 24px - 2px);
  display: flex; 
  flex-direction: row;
  /*overflow: hidden; /* Prevent scrolling on the main container */
  justify-content: center; /* Center children horizontally */
}
