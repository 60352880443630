.analyticsBody {
  display: flex;
  justify-content: left;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.powerbi-container {
  padding: 20px;
  width: calc(100% - 5px);      
  height: calc(100% - 5px);
  box-sizing: border-box;
}
  
.powerbi-container iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.splash-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1.2em;
  color: #666;
  gap: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  pointer-events: none;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.splash-screen.fade-out {
  opacity: 0;
}

.loading-circle {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.loading-circle.finished {
  opacity: 0;
}

.loading-stages {
  text-align: center;
  min-height: 24px;
  animation: fadeInOut 2s ease-in-out infinite;
}

.loading-stages.finished {
  animation: none;
  opacity: 1;
}

@keyframes fadeInOut {
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
}
