.question-answer-section {
    padding: 30px;
  }

.qa-li-question {
  margin:0; 
  margin-left:0px;
  padding:2px;
  list-style-type: none;
}

.qa-li-answer {
  margin:0;
  margin-left: 0;
  padding:0;
  padding-top:7px;
  list-style-type: none;
}