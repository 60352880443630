.transcript-main {
  display:flex;
  justify-content: left;
}

.transcript-content-container {
  display: flex;
  flex: 1; /* Flex grow to take up available space */
  transition: all 0.3s ease-in-out; /* Smooth transition for resizing */
}

.transcript-pane {
    margin: 8px;
    border: 2px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: white;
    height: calc(100vh - 70px - 50px);
    padding: 0px;
    flex: 1; /* Each pane takes equal width */
  }
  
  .transcript-summary-pane {
    min-width: 600px;
    overflow: auto; /* Enable scrolling */
  }
  
  .transcript-right-pane {
    height: calc(100vh - 70px - 50px);
    padding: 0px;
    flex: 1; /* Each pane takes equal width */
  }

  .transcript-detail-pane {
    margin: 8px;
    border: 2px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: white;
    height: calc(100vh - 70px - 50px);
    padding: 0px;

  }

