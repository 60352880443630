.source-document-pdf-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.source-document-pdf-iframe {
    border: none;
    width: 100%;
    height: 100%;
}