.mainBox {
  display:flex;
  justify-content: center;
  height: 100%; 
}

.termsCard {
    height: calc(100% - 100px);
    min-height: 300px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0px;
    width: 700px;
    min-width: 400px;
    max-width: 750px;
    overflow: hidden; /* Enable scrolling */
    margin: 50px;
  }

  .customCardContent {
    height: calc(100% - 100px);
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .cardActions {
    display: flex;
    justify-content: flex-end; /* Align the button to the right */
    padding: 8px 16px; /* Add some padding around the button */
    padding-right: 32px; /* Increase right padding */
  }