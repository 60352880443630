.guidance-table-card {
  padding: 10px; /* Reduced padding */
  width: 100%; /* Take full width */
  box-sizing: border-box;
}

.pane-content {
  max-width: 100%;
  overflow-x: auto;
}

.searchDataGrid .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}

.selected-row {
  color: #2596D1;
}

/* New styles to ensure DataGrid takes full width */
.searchDataGrid {
  width: 100% !important;
  height: 100% !important;
}
