.welcome-container {
    height: 100%;
    display: flex;
  }

  .header-section {

    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }

  .header-section h1 {
    margin-bottom: 0.5rem;  
}

.header-main {
    margin-bottom: 0.5rem;  
}

.header-sub-1 {
    margin-top: 0.1rem;  
    margin-bottom: 0.1rem;
    font-size: calc(8px + 2vmin);
}

.header-sub-2 {
    margin-top: 0.1rem;  
    margin-bottom: 0.1rem;
    font-size: max(calc(8px + 2vmin), 20px);
}

.header-sub-3 {
    margin-top: 10px;  
    font-size: max(calc(4px + 1.5vmin), 14px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; 
}
