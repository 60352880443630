.company-detail-container {
    height: calc(100vh - 62px - 24px - 2px - 60px - 100px);
    /* 
    60px = CompanyHeader.js
    100px = tabs
    */
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #dadddd;
}

.metric-container {
    overflow: auto;
}

.source-document-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

/* Ensure SourceDocument takes full height of its container */
.source-document-container > div {
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* Style for the source document title */
.source-document-container h3 {
    margin: 0;
    padding: 10px 0;
    text-align: center;
}

/* Ensure SourceDocumentTranscript takes remaining height and scrolls */
.source-document-container .source-document-transcript-pane {
    flex-grow: 1;
    overflow: auto;
}