.event-metric-list-boundary-box {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: 5px;
    overflow: auto;
}

.search-data-grid .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
}
.search-data-grid .MuiDataGrid-columnHeaders {
    min-height: 40px !important;
    max-height: 40px !important;
}

.search-data-grid .MuiDataGrid-columnHeader {
    height: 40px !important;
    line-height: 40px !important;
}

.search-data-grid .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 !important;
}

.search-data-grid .MuiDataGrid-cell:focus,
.search-data-grid .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.search-data-grid .MuiDataGrid-columnHeader:focus,
.search-data-grid .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
}

.search-data-grid .MuiDataGrid-row.Mui-selected {
    color: #2596D1;
}
