.company-header-container {
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.company-name {
  font-size: 1.5rem; /* Slightly larger text */
  margin-top: 8px;   /* Space above the company name */
  text-align: center;
}

.company-exchange {
  font-size: 0.875rem; /* Smaller text for exchange and ticker */
  text-align: center;
  margin-top: 4px;    /* Space between company name and exchange/ticker */
}
