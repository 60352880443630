body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-title-bar {
  height: 70px;
  border-bottom: 1px solid #dadddd;
  background-color: #d7dadb;
}

.card-main-title {
  font-size: 1.2em; 
  font-weight: bold; 
  margin: 0 0 5px 0; 
  padding-left: 15px;
  padding-top: 5px;
}

.card-sub-title {
  font-size: 0.9em; 
  margin: 0; 
  padding-left: 20px;
  font-style: italic; 
}

.card-full-height-main {
  overflow: hidden; /* Disable scrolling in this pane */
  height: calc(100vh - 50px - 70px);
}


.card-full-height-body {
  overflow: auto; /* Enable scrolling in this pane */
  height: calc(100vh - 50px - 70px - 70px);
}

