.search-container {
  display: flex;
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  padding: 2px 8px;
  width: 250px;
  background-color: transparent;
  height: 30px; 
  transition: border-color 0.3s;
}

.search-icon {
  margin-right: 8px;
  color: white; /* Hourglass color */
}

.search-input {
  flex: 1;
  font-size: 14px;
  color: white;
}

.clear-icon {
  margin-left: 4px;
  padding: 4px;
}

.search-dropdown {
  position: absolute;
  top: calc(62px - 24px); /* 62px Position below the search bar */
  left: 0;  /* Align dropdown to the search box */
  transform: translateX(0px); /* Adjust to properly align with the search box */
  width: 450px; /* Dropdown can be wider than the input */
  max-height: calc(100vh - 62px - 24px - 2px);
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-subheader {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 4px 8px; /* Reduce padding to reduce height */
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 4px 8px;  /* Reduce padding to reduce height */
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-item.Mui-selected, .dropdown-item:hover {
  background-color: #e0f7fa; /* Highlight color for selected/hovered item */
}

.dropdown-text {
  font-size: 14px;
  margin-left: 8px;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
}
